import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import styles from './a1-design-tokens-docs-app.scss';
import { notificationService } from './notification/services/notification.service.js';
import { fileFormats } from './shared/utils';

@customElement('a1-design-tokens-docs-app')
export class A1DesignTokensDocsApp extends LitElement {
	async connectedCallback() {
		if (super.connectedCallback) {
			super.connectedCallback();
		}
		// Dynamically import the child component when the parent component is connected
		await import('./list/components/list.component.js');
		await import('./notification/components/notification.component.js');
	}

	static styles = styles;

	firstUpdated() {
		const notificationElement = this.shadowRoot.getElementById('notification');
		notificationService.register(notificationElement);
	}

	render() {
		return html`
			<main>
				<div class="preview-banner"><p>WIP</br>Preview</p></div>
				<token-list defaultFormat="${fileFormats.json}"></token-list>
			</main>
			<notification-message id="notification"></notification-message>
		`;
	}
}
