class NotificationService {
	constructor() {
		this.notificationElement = null;
	}

	register(notificationElement) {
		this.notificationElement = notificationElement;
	}

	show(message, type, duration = 10000) {
		if (this.notificationElement) {
			this.notificationElement.show(message, type, duration);
		} else {
			console.error('NotificationComponent has not been registered with the NotificationService.');
		}
	}
}

export const notificationService = new NotificationService();
